import type { FC, ComponentProps } from 'react';
import React from 'react';

import { ByLine, ContentTitle } from '@confluence/content-header';

import { useViewPageParams } from './useViewPageParams';

type ByLinePassThroughProps = Pick<
	ComponentProps<typeof ByLine>,
	'hasByLineContributors' | 'hasByLineExtensions'
>;

type Props = Partial<ByLinePassThroughProps> &
	ComponentProps<typeof ContentTitle> & {
		hasCoverPicture?: boolean;
		pageWidthType?: string;
		isEmbeddedPage?: boolean;
		children?: React.ReactNode;
		publicLinkId?: string;
		isContentIdRemovalEnabled?: boolean;
	};

export const ContentTitleWithByLine: FC<Props> = ({
	contentId,
	spaceKey,
	banner,
	hasByLineContributors,
	hasByLineExtensions,
	headingContainer,
	hasCoverPicture,
	pageWidthType,
	isEmbeddedPage,
	children,
	publicLinkId,
	isContentIdRemovalEnabled,
}: Props) => {
	const { versionOverride, embeddedContentRender } = useViewPageParams();
	// TODO: this can be hoisted out of here as part of MDS-508
	const shouldUsePublicLinkId = isContentIdRemovalEnabled && publicLinkId;

	const renderContentTitleWithByLine = () => {
		return (
			<ContentTitle
				banner={banner}
				contentId={shouldUsePublicLinkId ? publicLinkId : contentId}
				spaceKey={spaceKey}
				headingContainer={headingContainer}
				hasCoverPicture={hasCoverPicture}
				pageWidthType={pageWidthType}
				isEmbeddedPage={isEmbeddedPage}
				isContentIdRemovalEnabled={isContentIdRemovalEnabled}
			>
				{children}
				<ByLine
					contentId={contentId}
					versionOverride={versionOverride}
					embeddedContentRender={embeddedContentRender}
					hasByLineContributors={hasByLineContributors}
					hasByLineExtensions={hasByLineExtensions}
				/>
			</ContentTitle>
		);
	};

	return renderContentTitleWithByLine();
};
